import type { FC } from 'react';

import type { Theme } from '../../..';

import { DefaultWelcomeBase } from '../containers';

interface Props {
  isPalmDisclaimerVisible: boolean;
  theme?: Theme;
}

export const WelcomeDefault: FC<Props> = ({ isPalmDisclaimerVisible, theme }) => (
  <DefaultWelcomeBase isPalmDisclaimerVisible={isPalmDisclaimerVisible} theme={theme}/>
)
