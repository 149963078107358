export enum SubscriptionState {
  ACTIVE = 'active',
  PAUSED = 'paused',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELED = 'cancelled',
  EXPIRED = 'expired',
  PAUSED = 'paused',
} 
