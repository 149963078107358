export async function process(imgUrl, { linePointsAmount } = {}) {
  const formData = new FormData();

  formData.append('file', imgUrl.replace('data:image/jpeg;base64,', ''));

  formData.append('linePointsAmount', linePointsAmount);

  return fetch(`https://hand-detection.astroline.today/lines/`, {
    method: 'POST',
    body: formData,
  })
    .then(r => r.json())
    .then(r => {
      // ругаемся на правую руку и на левую тыльной стороной
      if (r?.is_flipped || r?.hand_points?.[4]?.x > r?.hand_points?.[0]?.x) {
        return {};
      }
      return r
    });

}

export function dist(p, ref) {
  return Math.sqrt((p.x - ref.x) ** 2 + (p.y - ref.y) ** 2);
}
