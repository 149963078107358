import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage';

import { terminateSlice, manageSlice } from '@web-solutions/manage-subscription';
import { faceReadingSlice } from '@web-solutions/face-reading/src/store';
import { accountManagementSlice, accountManagementSliceBlacklist } from '@web-solutions/account-management';
import { palmReadingSlice } from '@web-solutions/palm-reading';

import remoteConfig from 'core/store/remote-config';
import billing from 'core/store/billing';
import { quizSlice } from 'core/store/quiz';

import { appSlice, blacklist as appBlacklist } from 'src/store/app';
import { profileSlice, blacklist as profileBlacklist } from 'src/store/profile';
import { routingSlice, whitelist as whitelistRouting } from 'src/store/routing';
import { chatSlice, blacklist as chatBlackList } from 'src/store/chat';
import { tarotSlice } from 'src/store/tarot';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const keyPrefix = `quiz-pp::`;

  const rootReducer = combineReducers({
    [appSlice.name]: persistReducer({
      key: `app`,
      storage,
      blacklist: appBlacklist,
    }, appSlice.reducer),
    remoteConfig: persistReducer({
      key: `${keyPrefix}remoteConfig`,
      storage,
      blacklist: remoteConfig.blacklist,
      whitelist: remoteConfig.whitelist,
    }, remoteConfig),
    routing: persistReducer({
      key: `${keyPrefix}routing`,
      storage,
      whitelist: whitelistRouting,
    }, routingSlice.reducer),
    billing: persistReducer({
      key: `${keyPrefix}billing`,
      storage,
      blacklist: billing.blacklist,
      whitelist: billing.whitelist,
    }, billing),
    [profileSlice.name]: persistReducer({
      key: `${keyPrefix}profile`,
      storage: storageSession,
      blacklist: profileBlacklist,
    }, profileSlice.reducer),
    [chatSlice.name]: persistReducer({
      key: `${keyPrefix}chat`,
      storage,
      blacklist: chatBlackList,
    }, chatSlice.reducer),
    [tarotSlice.name]: persistReducer({
      key: `${keyPrefix}tarot`,
      storage,
    }, tarotSlice.reducer),
    [quizSlice.name]: quizSlice,
    [terminateSlice.name]: terminateSlice.reducer,
    [manageSlice.name]: persistReducer({
      key: `manage`,
      storage,
      whitelist: ['hasBeenOnEmailCancelPage']
    }, manageSlice.reducer),
    [faceReadingSlice.name]: persistReducer({
      key: `${keyPrefix}${faceReadingSlice.name}`,
      storage,
    }, faceReadingSlice.reducer),
    [accountManagementSlice.name]: persistReducer({
      key: `${keyPrefix}${accountManagementSlice.name}`,
      storage,
      blacklist: accountManagementSliceBlacklist,
    }, accountManagementSlice.reducer),
    [palmReadingSlice.name]: persistReducer({
      key: `${keyPrefix}${palmReadingSlice.name}`,
      storage,
    }, palmReadingSlice.reducer),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
