import html2canvas from "html2canvas";

export async function makePalmScreenshotWithLines(view: HTMLElement, id?: string) {
  let renderedCanvas = await html2canvas(view, {
    onclone: function (clonedDoc) {
      if (clonedDoc && id) {
        const elem = clonedDoc.getElementById(id);
        if (elem) {
          elem.style.display = 'flex'
        }
      }
    }
  });

  const dataUrl = renderedCanvas.toDataURL('image/jpeg', 0.6);

  return dataUrl;
}