import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _flatMap from 'lodash/flatMap';
import React, { useEffect, useRef, } from 'react';
import Konva from 'konva';
import classNames from 'classnames';

import { LINES_COLORS } from '../../../constants';

import { usePalmReport } from '../../../hooks/use-palm-report';

import classes from './style.module.scss';

interface PalmReadingAnalyzingLinesProps {
  progress: number,
  style?: React.CSSProperties,
}

const PalmReadingAnalyzingLines: React.FC<PalmReadingAnalyzingLinesProps> = ({ progress, style, }) => {

  const { lines, dims, keypoints: points } = usePalmReport();

  const FINGERS_PORTION = points?.length ? 20 : 1;

  const STEP = (100 - FINGERS_PORTION) / Object.keys(lines).length;

  const konva = useRef<Array<HTMLDivElement | null>>([]);

  const ratio = dims.ratio || 1;

  useEffect(() => {
    _forEach(Object.keys(lines), (l, index) => {
      const stage = new Konva.Stage({
        container: konva.current[index],
        ...dims
      });
      const layer = new Konva.Layer();
      const line = new Konva.Line({
        points: _flatMap(lines[l], (p) => ([p.x / ratio, p.y / ratio])),
        stroke: LINES_COLORS[l],
        strokeWidth: 4.8,
        lineCap: 'round',
        lineJoin: 'round',
        tension: 0.5,
      });
      layer.add(line);
      stage.add(layer);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines]);

  return (
    <div className={classes.root} style={style}>
      {_map(points, (p, i) => (
        <div
          key={i}
          className={classNames(classes.point, { [classes.visible]: progress > i / points!.length * FINGERS_PORTION })}
          style={{ left: p.x / ratio - 4, top: p.y / ratio - 4, backgroundColor: 'white' }}
        />
      ))}
      {_map(Object.keys(lines), (l, index) => (
        <React.Fragment key={l}>
          {_map(lines[l], (p, i) => (
            <div
              key={i}
              className={classNames(classes.point, { [classes.visible]: progress - FINGERS_PORTION - STEP * index > i / lines[l].length * (STEP * 0.8) })}
              style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                left: p.x / ratio - 2,
                top: p.y / ratio - 2,
                backgroundColor: LINES_COLORS[l],
              }} />
          ))}
          <div
            ref={el => konva.current[index] = el}
            className={classNames(classes.konva, { [classes.visible]: progress - FINGERS_PORTION + 4 > STEP * (index + 1) })}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default PalmReadingAnalyzingLines;
