import type { ImagesMap } from '@web-solutions/core/interfaces/images';

import QUIZ_PALM_READING_RIGHT_LIGHT from '../../upload/popup/images/base_light.webp';
import QUIZ_PALM_READING_RIGHT_LIGHT_2X from '../../upload/popup/images/base_light@2x.webp';
import QUIZ_PALM_READING_RIGHT_LIGHT_3X from '../../upload/popup/images/base_light@3x.webp';

import QUIZ_PALM_READING_RIGHT_DARK from '../../upload/popup/images/base_dark.webp';
import QUIZ_PALM_READING_RIGHT_DARK_2X from '../../upload/popup/images/base_dark@2x.webp';
import QUIZ_PALM_READING_RIGHT_DARK_3X from '../../upload/popup/images/base_dark@3x.webp';

import QUIZ_PALM_READING_RIGHT from './right.webp';
import QUIZ_PALM_READING_RIGHT2X from './right@2x.webp';
import QUIZ_PALM_READING_RIGHT3X from './right@3x.webp';

export const IMAGES: ImagesMap = {
  QUIZ_PALM_READING_RIGHT: {
    src: QUIZ_PALM_READING_RIGHT,
    srcSet: `${QUIZ_PALM_READING_RIGHT}, ${QUIZ_PALM_READING_RIGHT2X} 2x, ${QUIZ_PALM_READING_RIGHT3X} 3x`,
    themes: {
      light: {
        src: QUIZ_PALM_READING_RIGHT_LIGHT,
        srcSet: `${QUIZ_PALM_READING_RIGHT_LIGHT}, ${QUIZ_PALM_READING_RIGHT_LIGHT_2X} 2x, ${QUIZ_PALM_READING_RIGHT_LIGHT_3X} 3x`,
      },
      dark: {
        src: QUIZ_PALM_READING_RIGHT_DARK,
        srcSet: `${QUIZ_PALM_READING_RIGHT_DARK}, ${QUIZ_PALM_READING_RIGHT_DARK_2X} 2x, ${QUIZ_PALM_READING_RIGHT_DARK_3X} 3x`,
      }
    }
  }
};
