import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export interface RootState {
  palmReading: PalmReadingState;
}

export interface PalmReadingState {
  palmReport: any;
  handImg: any;
  handImgDims: any;
  handKeyPoints: any;
  handBasePoint: any;
  handLines: any;
  originalLines: any;
}

const initialState: PalmReadingState = {
  palmReport: null,
  handImg: null,
  handImgDims: null,
  handKeyPoints: null,
  handBasePoint: null,
  handLines: null,
  originalLines: null,
};

export const slice = createSlice({
  name: 'palmReading',
  initialState,
  reducers: {
    setPalmReport: (state, action: PayloadAction<any>) => {
      state.palmReport = action.payload
    },
    setHandImg: (state, action: PayloadAction<any>) => {
      state.handImg = action.payload.img;
      state.handImgDims = { width: action.payload.width, height: action.payload.height, ratio: action.payload.ratio, };
      state.handKeyPoints = action.payload.keypoints;
      state.handBasePoint = action.payload.basePoint;
      state.handLines = action.payload.lines;
      state.originalLines = action.payload.originalLines;
    },
  },
})

const store = configureStore({
  reducer: slice.reducer,
})

export type AppDispatch = typeof store.dispatch
export const useSliceDispatch: () => AppDispatch = useDispatch;
