import { ImagesMap } from '@web-solutions/core/interfaces/images';

import BG from './bg.webp';
import BG_X2 from './bg@2x.webp';
import BG_X3 from './bg@3x.webp';

export const IMAGES: ImagesMap = {
  BG: {
    src: BG,
    srcSet: `${BG}, ${BG_X2} 2x, ${BG_X3} 3x`,
  },
};
