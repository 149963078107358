import React from 'react';
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization';

import { Image } from '@web-solutions/core/ui-elements'

import EP from '@web-solutions/base-app/components/error-popup';

import { IMAGES } from '../welcome/images';

import type { Theme } from '../..';

import classes from './style.module.scss';

const tKey = 'palm_reading.error_popup';

interface Props{
  visible: boolean;
  title?: string
  onClose: () => void;
  onSkipClick: () => void;
  theme?: Theme;
}

const ErrorPopUp: React.FC<Props> = ({ visible, title, onClose, onSkipClick, theme }) => {

  const isDarkTheme = theme?.isDarkTheme;
  const isLightTheme = theme?.isLightTheme;

  const isAlternativeTheme = isDarkTheme || isLightTheme;

  return (
    <EP
      isOpen={visible}
      title={title || t(`${tKey}.title`)}
      buttonTitle={t(`${tKey}.btn`)}
      skipButtonTitle={t(`${tKey}.skip`)}
      onClose={onClose}
      onSkipClick={onSkipClick}
    >
      <div className={classNames(classes.img, { [classes.without_bg]: isAlternativeTheme })}>
        <Image img={IMAGES.QUIZ_PALM_READING_RIGHT} alt='right' />
      </div>
    </EP>
  );
}

export default ErrorPopUp;
