import _forEach from 'lodash/forEach';
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import dayjs from 'dayjs';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import UAParser from 'ua-parser-js';

import EvTruck from '@magnus/react-native-evtruck';
import EvTruckAPI from '@magnus/react-native-evtruck/src/api';
import Mutator from '@magnus/react-native-mutator';
import DeviceProps from '@magnus/react-native-device-props';

import Session from '@wowmaking/react-native-analytics/src/core/helpers/session';

import Attribution, { UTM_PARAMS } from '@web-solutions/module-attribution';
import { trackEvent as trackEventSP, setUserProperty as setUserPropertySP } from '@web-solutions/sub-predict';

import { getDefaultExternalEventsConfig } from './utils/get-default-external-events-config'

import type { SubscriptionPurchase, OneTimePurchase } from './types'

declare global {
  interface Window {
    dataLayer?: Record<string, any>[];
  }
}

export interface SubtruckExternalEventConfig {
  service: 'google_analytics' | 'facebook_conversion_api' | 'pinterest_conversion_api' | 'tik_tok_conversion_api' | 'snapchat_conversion_api';
  name: string;
  value: number;
  currency: string;
  subscription_event_status: string;
  additional_params?: {
    predicted_ltv?: number;
  }
}

let IDFM: string | undefined = undefined;
let MAGNUS_TOKEN: string | undefined = undefined;
let SUBTRUCK_EXTERNAL_EVENTS: Record<string, SubtruckExternalEventConfig[]> = {};
let IS_INITED = false;

const BOTS = [
  'Google-Read-Aloud',
  'AdsBot-Google',
  'adsbot-crawler', // snapchat crawler
]

const p = queryString.parse(window.location.search);

export const disabled =
  !!BOTS.find(i => window.navigator?.userAgent?.includes?.(i))
  ||
  (!!p.campaign_id && !p.utm_source); // TikTok weird sessions

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.025,
});

const Analytics = {
  preinit(magnusToken: string, appVersion: string) {
    MAGNUS_TOKEN = magnusToken;
    DeviceProps.setAppVersion(appVersion);
    EvTruckAPI.init({ token: magnusToken });
  },

  init(magnusToken: string, appVersion: string, options?: { skipSessionIncrement?: boolean, evtruckStorageKey?: string }) {
    DeviceProps.setAppVersion(appVersion);

    if (disabled) {
      return Promise.resolve();
    }

    const attribution = Attribution.getAttribution();

    MAGNUS_TOKEN = magnusToken;

    DeviceProps.getIDFM()
      .then(idfm => {
        IDFM = idfm;

        Sentry.setUser({ id: idfm });

        window.dataLayer?.push({
          idfm,
        });
        IS_INITED = true;
      });

    EvTruck.init({
      token: magnusToken,
      storageKey: options?.evtruckStorageKey,
    });

    const p = queryString.parse(window.location.search);
    const partition = window.location.pathname.split('/')[1];

    Mutator.setAttribution({ source: attribution?.utm_source || '', campaign_id: attribution?.campaign_id || '', });

    if (attribution?.install_time) {
      try {
        const cohort_date = dayjs(attribution.install_time).format('YYYY-MM-DD');
        this.setUserProperty('cohort_date', cohort_date);
        window.localStorage.setItem('@WRNA/COHORT_DATE', cohort_date);
        window.localStorage.setItem('@WRNA/SESSION_NUMBER', window.localStorage.getItem('@WRNA/SESSION_NUMBER') || '0');
      }
      catch { }
    }

    return (options?.skipSessionIncrement ? Promise.resolve() : Session.init(this)).then(() => {
      this.setUserProperty('trigger', p.trigger ? String(p.trigger) : undefined);

      this.setUserProperty('domain', window.location.hostname);
      this.setUserProperty('partition', partition);

      if (attribution?.utm_source) {
        this.setUserProperty('utm_source', attribution.utm_source);
      }

      if (attribution?.ad_subnetwork) {
        this.setUserProperty('ad_subnetwork', attribution.ad_subnetwork);
      }

      if (attribution?.campaign_id) {
        this.setUserProperty('campaign_id', attribution.campaign_id);
      }

      if (attribution?.adset_id) {
        this.setUserProperty('adset_id', attribution.adset_id);
      }

      const attributionUrlParams = queryString.parseUrl(attribution?.url || '').query;

      this.setUserProperty('mode', String(attributionUrlParams.mode || ''));

      if (attributionUrlParams.creative_topic) {
        this.setUserProperty('ua_creative_topic', String(attributionUrlParams.creative_topic));
      }

      this.trackEvent('session', 'url_params',
        _mapValues(
          _omit(p, UTM_PARAMS),
          String
        )
      );

      try {
        if (!window.localStorage.getItem('__install_time__')) {
          window.localStorage.setItem('__install_time__', String(Date.now()));
        }
      }
      catch (ex) { }

      if (p.reset !== undefined) {
        setInterval(() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
        }, 10);
        setTimeout(() => {
          delete p.reset;
          window.location.href = queryString.stringifyUrl({ url: window.location.origin + window.location.pathname, query: p });
        }, 1000);
      }

      window.addEventListener('beforeunload', () => {
        this.trackEvent('session', 'end');
        this.flush();
      });

      window.addEventListener('visiibilitychange', () => {
        this.flush();
      });

      return p;
    });
  },

  trackMutatorSessionFirst() {
    let partition = window.location.pathname.split('/')[1];
    const p = queryString.parse(window.location.search);
    const r = new UAParser().getResult();
    this.trackEvent('mutator', 'session_first', { partition, mode: p.mode, utm_source: p.utm_source, browser: r?.browser?.name });
  },

  setUserProperty(name: string, value: string | boolean | null | undefined) {
    //@ts-ignore
    EvTruck.setUserProperty(name, value);
    setUserPropertySP(name, String(value || ''));
  },

  trackEvent(category: string, action: string, params?: Record<string, unknown>) {
    // console.log('trackEvent:', category, action, params);
    const event = `${category}_${action}`;
    EvTruck.trackEvent(event, params);
    trackEventSP(event, params);
    window.dataLayer?.push({ event, ...params }); // GTM
  },

  prepareSubscriptionPurchaseExternalEvents({ paypalPlanId, productId, ...baseParams }: SubscriptionPurchase) {
    fetchExternalEvents({
      productId,
      delay: 16, // 16 - avg completion time modal_payment_open -> modal_payment_success
      ...baseParams,
    });

    if (paypalPlanId) {
      fetchExternalEvents({
        productId: paypalPlanId,
        delay: 16,
        ...baseParams,
      });
    }
  },

  prepareOneTimePurchaseExternalEvents({ productId, value, currency, trigger }: OneTimePurchase) {
    if (IS_INITED) {
      fetchExternalEvents({
        productId: trigger ? `${productId}:${trigger}` : productId,
        value,
        currency,
        delay: 2,
      });
    }
  },

  trackPurchaseEvent(transaction: {
    transactionId: string,
    productId: string,
    revenue: number,
    currency: string,
    method: string,
    paymentSystem: string,
  }) {
    EvTruck.trackEvent('ecommerce_purchase', {
      transactionId: transaction.transactionId,
      productId: transaction.productId,
      value: transaction.revenue,
      currency: transaction.currency,
      method: transaction.method,
      paymentSystem: transaction.paymentSystem,
      externalEvents: _filter(SUBTRUCK_EXTERNAL_EVENTS[transaction.productId], { subscription_event_status: 'subscribe_with_introductory_period' }).length || 'null',
    });

    window.dataLayer?.push({
      event: 'purchase',
      currency: transaction.currency,
      value: Number(transaction.revenue),
      transaction_id: transaction.transactionId,
      product_id: transaction.productId,
      method: transaction.method,
    });

    sendExternalEvents({ ...transaction, isOneTimePurchase: false });
  },

  trackOneTimePurchaseEvent(transaction: {
    transactionId: string,
    productId: string,
    amount: string,
    currency: string,
    type: 'order' | '1click',
    trigger: string,
    method?: string,
    paymentSystem?: string,
  }) {
    const productId = transaction.trigger ? `${transaction.productId}:${transaction.trigger}` : transaction.productId;

    EvTruck.trackEvent('onetime_purchase_success', {
      transactionId: transaction.transactionId,
      productId: transaction.productId,
      amount: transaction.amount,
      currency: transaction.currency,
      method: transaction.method,
      paymentSystem: transaction.paymentSystem,
      trigger: transaction.trigger,
      externalEvents: _filter(SUBTRUCK_EXTERNAL_EVENTS[productId], { subscription_event_status: 'purchased' }).length || 'null',
    });

    window.dataLayer?.push({
      event: 'onetime_purchase',
      currency: transaction.currency,
      value: Number(transaction.amount),
      transaction_id: transaction.transactionId,
      product_id: transaction.productId,
      method: transaction.method,
      trigger: transaction.trigger,
    });

    sendExternalEvents({ ...transaction, productId, isOneTimePurchase: true });
  },

  getSessionNumber() {
    return Session.getNumber();
  },

  flush() {
    if (IS_INITED) {
      EvTruck.flush();
    }
  },
};

function fetchExternalEvents(params: {
  productId: any,
  value: any,
  currency: any,
  delay: number,
}) {
  const p = queryString.parse(window.location.search);
  const installTime = +(window.localStorage.getItem('__install_time__') || Date.now());
  Mutator.getCountryCode()
    .then(countryCode =>
      fetch(queryString.stringifyUrl({
        url: 'https://subtruck.magnus.ms/api/v2/external-services-events/',
        query: {
          product_code: params?.productId,
          price: params?.value,
          currency: params?.currency,
          country: countryCode,
          seconds_after_install: Math.round((Date.now() - installTime) / 1000) + params.delay,
          source: p.utm_source,
          idfm: IDFM,
        }
      }), {
        method: 'GET',
        headers: {
          'Authorization': MAGNUS_TOKEN!,
        }
      })
    )
    .then(r => {
      if (r.ok) {
        return r.json()
      } else {
        return Promise.reject()
      }
    })
    .then(data => {
      SUBTRUCK_EXTERNAL_EVENTS[params.productId] = data?.data;
    })
    .catch(() => {
      if (!SUBTRUCK_EXTERNAL_EVENTS[params.productId]) {
        SUBTRUCK_EXTERNAL_EVENTS[params.productId] = getDefaultExternalEventsConfig({ value: params?.value, currency: params?.currency })
      }
    });
}

function sendExternalEvents(transaction: {
  transactionId: string,
  productId: string,
  isOneTimePurchase: boolean,
}) {
  const event = transaction.isOneTimePurchase ? 'purchased' : 'subscribe_with_introductory_period';
  _forEach(SUBTRUCK_EXTERNAL_EVENTS[transaction.productId], e => {
    if (e.subscription_event_status === event) {
      const value = e.value;
      const currency = e.currency;
      const eventID = e.name + '_' + transaction.transactionId;

      switch (e.service) {
        case 'google_analytics':
          //@ts-ignore
          window.gtag && window.gtag('event', e.name, {
            value,
            currency,
            transaction_id: transaction.transactionId,
          });
          break;
        case 'facebook_conversion_api':
          //@ts-ignore
          window.fbq && window.fbq('track', e.name, {
            value,
            currency,
            predicted_ltv: e.additional_params?.predicted_ltv,
          }, {
            eventID,
          });
          break;
        case 'pinterest_conversion_api':
          //@ts-ignore
          window.pintrk && window.pintrk('track', e.name, {
            value,
            currency,
            event_id: eventID,
          });
          break;
        case 'tik_tok_conversion_api':
          const event_id = e.name + String(transaction.transactionId).replaceAll('-', '');
          // @ts-ignore
          window.ttq && window.ttq.track(e.name, {
            event_id: event_id,
            contents: [
              {
                content_id: transaction.productId,
                content_type: 'product',
                price: value,
                quantity: 1
              }],
            content_type: 'product',
            value,
            currency,
          });
          break;
        case 'snapchat_conversion_api':
          // @ts-ignore
          window.snaptr && window.snaptr('track', e.name, {
            price: value,
            currency,
            transaction_id: transaction.transactionId,
            client_dedup_id: eventID,
            predicted_ltv: e.additional_params?.predicted_ltv,
          });
          break;
      }
    }
  });
}

export default Analytics;
