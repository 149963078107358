import { ImagesMap } from "@web-solutions/core/interfaces/images";

import BASE from './base.webp';
import BASE_2X from './base@2x.webp';
import BASE_3X from './base@3x.webp';

import BASE_LIGHT from './base_light.webp';
import BASE_LIGHT_2X from './base_light@2x.webp';
import BASE_LIGHT_3X from './base_light@3x.webp';

import BASE_DARK from './base_dark.webp';
import BASE_DARK_2X from './base_dark@2x.webp';
import BASE_DARK_3X from './base_dark@3x.webp';

import ERROR from './error.webp';
import ERROR_2X from './error@2x.webp';
import ERROR_3X from './error@3x.webp';

import ERROR_LIGHT from './error_light.webp'
import ERROR_LIGHT_2X from './error_light@2x.webp'
import ERROR_LIGHT_3X from './error_light@3x.webp'

import ERROR_DARK from './error_dark.webp'
import ERROR_DARK_2X from './error_dark@2x.webp'
import ERROR_DARK_3X from './error_dark@3x.webp'

export const IMAGES: ImagesMap = {
  BASE: {
    src: BASE,
    srcSet: `${BASE}, ${BASE_2X} 2x, ${BASE_3X} 3x`,
    themes: {
      light: {
        src: BASE_LIGHT,
        srcSet: `${BASE_LIGHT}, ${BASE_LIGHT_2X} 2x, ${BASE_LIGHT_3X} 3x`,
      },
      dark: {
        src: BASE_DARK,
        srcSet: `${BASE_DARK}, ${BASE_DARK_2X} 2x, ${BASE_DARK_3X} 3x`,
      }
    }
  },
  ERROR: {
    src: ERROR,
    srcSet: `${ERROR}, ${ERROR_2X} 2x, ${ERROR_3X} 3x`,
    themes: {
      light: {
        src: ERROR_LIGHT,
        srcSet: `${ERROR_LIGHT}, ${ERROR_LIGHT_2X} 2x, ${ERROR_LIGHT_3X} 3x`,
      },
      dark: {
        src: ERROR_DARK,
        srcSet: `${ERROR_DARK}, ${ERROR_DARK_2X} 2x, ${ERROR_DARK_3X} 3x`,
      }
    }
  }
};