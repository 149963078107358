import Analytics from '@web-solutions/module-analytics';

import { DIMS, WIDTH_OFFSET } from '../../../constants';

interface CameraSetupOptions {
  initTimeout?: number;
  ratio?: number;
  withTorch?: boolean;
}

interface VideoElement extends HTMLVideoElement {
  videoWidth: number;
  videoHeight: number;
}

export class Camera {
  video: VideoElement;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  brightness?: number;

  constructor() {
    this.video = document.getElementById('video') as VideoElement;
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d');
  }

  static async setupCamera({ initTimeout, ratio = 1, withTorch = false }: CameraSetupOptions = {}): Promise<Camera> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      throw new Error('Browser API navigator.mediaDevices.getUserMedia not available');
    }

    const size = DIMS.height * ratio;

    const videoConfig: MediaStreamConstraints = {
      audio: false,
      video: {
        facingMode: 'environment',
        width: { ideal: size },
        height: { ideal: size },
        frameRate: { ideal: 20 },
      },
    };

    const stream = await Promise.race([
      navigator.mediaDevices.getUserMedia(videoConfig),
      new Promise<never>((_, reject) => {
        if (initTimeout) {
          setTimeout(() => {
            reject(new Error('timeout'));
          }, initTimeout);
        }
      }),
    ]);

    const camera = new Camera();

    const p = new Promise<HTMLVideoElement>((resolve) => {
      camera.video.onloadedmetadata = () => {
        resolve(camera.video);
      };
    });

    camera.video.srcObject = stream;

    try {
      camera.video.play();
      await p;
      camera.video.play();
      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities() as MediaTrackCapabilities & { torch?: boolean };

      if (capabilities.torch && withTorch) {
        await videoTrack.applyConstraints({
          advanced: [{ torch: true } as MediaTrackConstraintSet]
        });

        Analytics.trackEvent('palm_reading', 'with_torch');
      }
    } catch (ex) {
      console.warn(ex);
    }

    const videoWidth = camera.video.videoWidth;
    const videoHeight = camera.video.videoHeight;

    camera.video.width = videoWidth / ratio;
    camera.video.height = videoHeight / ratio;

    camera.canvas.width = window.innerWidth * ratio;
    camera.canvas.height = videoHeight;
    camera.canvas.style.width = `${window.innerWidth * ratio}px`;
    camera.canvas.style.height = `${videoHeight}px`;
    camera.canvas.style.maxHeight = `${videoHeight}px`;
    camera.canvas.style.minHeight = `${videoHeight}px`;
    camera.canvas.style.transform = `scale(${1 / ratio})`;

    const palmShape = document.getElementById('palm-shape')!;
    if (palmShape) {
      palmShape.style.width = `${Math.min(videoHeight / ratio, window.innerWidth)}px`;
      palmShape.style.height = `${videoHeight / ratio}px`;
    }

    return camera;
  }

  dispose(): void {
    try {
      const stream = this.video?.srcObject as MediaStream | null;
      if (stream) {
        stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      }

      this.canvas?.remove();
      this.video?.remove();
      this.ctx = null;
    } catch (ex) {
      console.warn(ex);
    }
  }

  drawCtx(): void {
    if (!this.ctx || !this.video) return;

    this.ctx.drawImage(
      this.video,
      WIDTH_OFFSET,
      0,
      this.video.videoWidth,
      this.video.videoHeight
    );

    try {
      if (Math.random() < 0.1) {
        const imageData = this.ctx.getImageData(0, 0, this.video.videoWidth, this.video.videoHeight);
        const data = imageData.data;
        let r, g, b, avg, sum = 0;
        const SKIP = 4;

        for (let x = 0, len = data.length; x < len; x += 4 * SKIP) {
          r = data[x];
          g = data[x + 1];
          b = data[x + 2];
          avg = Math.floor((r + g + b) / 3);
          sum += avg;
        }

        this.brightness = Math.floor(sum * SKIP / (this.video.videoWidth * this.video.videoHeight));
      }
    } catch (ex) {
      console.warn(ex);
    }
  }

  clearCtx(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.video.videoWidth, this.video.videoHeight);
    }
  }
}

