import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';

import { Input, Button } from '@web-solutions/core/ui-elements';

import { InitialData } from '../../create-account';

import { Passwords } from '../passwords';

import { AttentionIcon } from './icons'

import classes from './style.module.scss';

const tKey = 'create_account';

interface Props {
  data: InitialData;
  validation: { [key in 'email' | 'password' | 'confirm_password']: boolean };
  isEmailOnly: boolean;
  isLoading: boolean;
  withLetterIcon?: boolean;
  withInputLabels: boolean;
  onSubmit: (e: React.FormEvent) => void;
  onInputChange: (e: any) => void;
}

export const EmailForm: React.FC<Props> = ({ onSubmit, onInputChange, validation, data, isLoading, isEmailOnly, withLetterIcon, withInputLabels }) => {
  return (
    <form onSubmit={onSubmit} noValidate className={classNames(classes.form, { [classes.email_only]: isEmailOnly })}>
      <div className={classes.fields_wrapper}>
        <label className={classes.field_wrap}>
          {withInputLabels && (
            <p className={classes.name}>
              <T k={`${tKey}.fields.email.title`} />
            </p>
          )}
          <div className={classes.field_with_icon}>
            <Input
              autoComplete="email"
              type='email'
              name='email'
              value={data.email}
              placeholder={t(`${tKey}.fields.email.placeholder`)}
              className={classNames(classes.field, { [classes.error_border]: !validation.email })}
              onChange={onInputChange}
            />
            {!validation.email && (
              <AttentionIcon className={classNames(classes.field_icon, classes.attantion_icon)} />
            )}
          </div>
          {!validation.email && (
            <p className={classes.error}>
              {t(`${tKey}.errors_messages.email`)}
            </p>
          )}
        </label>
        <Passwords
          validation={validation}
          onInputChange={onInputChange}
          data={data}
          withInputLabels={withInputLabels}
        />
      </div>
      <Button
        type='submit'
        disabled={Object.values(validation).includes(false) || !Object.values(data).every(Boolean) || isLoading}
        className={classes.button}
        titleClassName={classNames(classes.button_title, { [classes.with_letter_icon]: withLetterIcon })}
        title={t(`${tKey}.form_button`)}
      />
    </form>
  )
};