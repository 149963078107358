import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { LoadMethod, DIMS, ImgData, PALM_RATIO } from '../../constants';

import { PalmReadingApproveFooter } from './footer';

import classes from './style.module.scss';

interface PalmReadingApproveProps {
  eventCategory: string;
  onCaptureSuccess: (data: ImgData) => void;
  onUploadSuccess: (data: ImgData) => void;
  onRetakeClick: (type: LoadMethod) => void;
  img: string;
  type: LoadMethod;
  isChatFlow?: boolean;
}

const tKey = 'palm_reading.QUIZ_PALM_READING_APPROVE';

const PalmReadingApprove: React.FC<PalmReadingApproveProps> = ({
  eventCategory,
  onCaptureSuccess,
  onUploadSuccess,
  onRetakeClick,
  img,
  type,
  isChatFlow,
}) => {
  const ratio = Math.max(Math.min(PALM_RATIO, window.devicePixelRatio || 1), 1);

  useEffect(() => {
    Analytics.trackEvent(eventCategory, 'open');
  }, [eventCategory]);

  const handleBackClick = () => {
    Analytics.trackEvent(eventCategory, 'not_approve');
    onRetakeClick(type);
  };

  const handleOkClick = () => {
    Analytics.trackEvent(eventCategory, 'success');
    if (type === LoadMethod.CAPTURE) {
      onCaptureSuccess({ img: img, ...DIMS, ratio })
    } else {
      onUploadSuccess({ img: img, ...DIMS, ratio })
    }
  };

  return (
    <div className={classes.wrapper}>
      <img src={img} alt="palm" width={DIMS.width} />
      <PalmReadingApproveFooter
        okText={isChatFlow ? t(`${tKey}.button_chat`) : t(`${tKey}.button`)}
        onOkClick={handleOkClick}
        cancelText={t(`${tKey}.back`)}
        onCancelClick={handleBackClick}
      />
    </div>
  );
};

export default PalmReadingApprove;
