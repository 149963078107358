import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { DIMS, LoadMethod, type ImgData, PALM_RATIO } from '../../constants';

import { Camera } from './camera';

import Shape from './shape';

import classes from './style.module.scss';

interface PalmReadingCaptureProps {
  cameraInitTimeout?: number;
  eventCategory: string;
  isChatFlow?: boolean;
  onSuccess: (data: ImgData, type: LoadMethod) => void;
  onError: (ex: unknown) => void;
  onBackClick: () => void;
}

const PalmReadingCapture: React.FC<PalmReadingCaptureProps> = ({ cameraInitTimeout, eventCategory, isChatFlow, onSuccess, onError, onBackClick }) => {
  const r = useRef(false);
  const camera = useRef<any>(false);
  const [showBrightnessWarning, setShowBrightnessWarning] = useState(false);

  const { cameraWithTorch } = useRemoteConfig();

  const ratio = Math.max(Math.min(PALM_RATIO, window.devicePixelRatio || 1), 1);

  useEffect(() => {
    Analytics.trackEvent(eventCategory, EVENT_ACTION.OPEN, { ...DIMS, ratio, });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      async function render() {
        if (!r.current) {
          camera.current.drawCtx();
          setShowBrightnessWarning(typeof camera.current.brightness === 'number' && camera.current.brightness < 50);
        }
        requestAnimationFrame(render);
      }

      try {
        //@ts-ignore
        camera.current = await Camera.setupCamera({ initTimeout: cameraInitTimeout, ratio, withTorch: cameraWithTorch });
        render();
      } catch (err) {
        onError(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCaptureClick = async () => {
    Analytics.trackEvent(eventCategory, EVENT_ACTION.CLICK);
    if (camera?.current) {
      r.current = true;
      try {
        camera.current.drawCtx();
        const img = camera.current.canvas.toDataURL('image/jpeg', 1);
        camera.current.dispose();
        onSuccess({ img, ...DIMS, ratio }, LoadMethod.CAPTURE);
      } catch (ex) {
        onError(ex);
        r.current = false;
      }
    } else {
      Analytics.trackEvent(eventCategory, 'not_ready');
    }
  };

  const handleBackClick = useCallback(() => {
    Analytics.trackEvent(eventCategory, 'click_back');
    onBackClick();
  }, [onBackClick, eventCategory]);

  return (
    <div className={classNames(classes.root, { [classes.chatflow]: isChatFlow })}>
      <div className={classes.back} onClick={handleBackClick} />
      <canvas id='canvas' className={classes.canvas} />
      <video id='video' className={classes.video} playsInline />
      <Shape />
      {showBrightnessWarning &&
        <span className={classes.warning}>{t('palm_reading.QUIZ_PALM_READING_CAPTURE.brightness')}</span>
      }
      <div className={classes.footer} onClick={handleCaptureClick}>
        <span className={classes.instruction}>{t('palm_reading.QUIZ_PALM_READING_CAPTURE.instruction')}</span>
        <button><span /></button>
      </div>
    </div>
  );
};

export default PalmReadingCapture;