import request from '@web-solutions/core/utils/network';;

export const getPalmReport = () => {
  return request.get('/image-recognition/palm-reading');
}

export const savePalm = (data) => request.post('/image-recognition/palm-reading?hand_type=left',
  data,
  {
    headers: {
      Accept: "*/*"
    }
  });