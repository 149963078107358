import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { T, t, TM } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title, Button, Preloader } from '@web-solutions/core/ui-elements';

import { ImageSource } from '@web-solutions/core/interfaces/images';

import ErrorPopUp from '@web-solutions/core/containers/error-popup';

import { useScrollOnFocus } from '@web-solutions/core/hooks/use-scroll-on-focus';
import { useSetKeyboardInsetHeightVar } from '@web-solutions/core/hooks/use-set-keyboard-inset-height-var';

import SuccessPopup from '../components/success-popup';
import { useReAuthentication } from '../hooks/use-re-authentication';

import { confirmPassword, reSendPasswordReset, handleRecoverEmail, signInUser, checkIsCodeValid } from '../api/auth';

import { setUid } from '../store/actions';

import { Passwords } from '../components/passwords';
import { SuccessScreen } from '../components/reset-success-screen';

import { useGetContinueUrl } from '../hooks/use-get-continue-url';

import classes from './style.module.scss';

const tKey = 'reset_password';

interface Props {
  title?: string;
  successImage: ImageSource;
  isCenteredSuccessContent?: boolean;
  withInputLabels?: boolean;
}

const categoryPassword = 'reset_password';
const categoryEmail = 'recover_email';

const ModuleReAuthentication: React.FC<Props> = ({ title, successImage, isCenteredSuccessContent, withInputLabels = true }) => {
  const dispatch = useDispatch();
  const { email, platform } = queryString.parse(window.location.search) as { email: string, platform: string };
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [showSuccessReSendPopup, setShowSuccessReSendPopup] = useState(false);
  const [showSuccessEmailRecoveredPopup, setShowSuccessEmailRecoveredPopup] = useState(false);
  const [error, setError] = useState<'link_used' | 'general' | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    data,
    validation,
    handleChange,
  } = useReAuthentication();

  const isValid = validation.password && validation.confirm_password && data.password && data.confirm_password;
  const { oobCode, mode } = queryString.parse(window.location.search) as { oobCode: string | null, mode: string | null };

  const { url: continueUrl  } = useGetContinueUrl();

  useSetKeyboardInsetHeightVar();
  useScrollOnFocus();

  useEffect(() => {
    if (mode === 'resetPassword') {
      Analytics.trackEvent(categoryPassword, EVENT_ACTION.OPEN);
    }
    if (mode === 'recoverEmail') {
      Analytics.trackEvent(categoryEmail, EVENT_ACTION.OPEN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const recoverEmail = async () => {
      if (oobCode && mode === 'recoverEmail') {
        try {
          setLoading(true);
          await handleRecoverEmail(oobCode);
          setShowSuccessEmailRecoveredPopup(true);
          Analytics.trackEvent(categoryEmail, EVENT_ACTION.SUCCESS);
        } catch (error: any) {
          setError('general');
          Analytics.trackEvent(categoryEmail, EVENT_ACTION.ERROR, { error: error?.message, code: error?.code });
        } finally {
          setLoading(false);
        }
      }
    };

    recoverEmail();
  }, [oobCode, mode]);

  useEffect(() => {
    if (mode === 'resetPassword' && oobCode) {
      checkIsCodeValid(oobCode)
        .catch((error: any) => {
          setError('link_used');
          Analytics.trackEvent(categoryPassword, EVENT_ACTION.ERROR, { error: error?.message });
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBtnClick = async () => {
    if (oobCode && mode === 'resetPassword') {
      try {
        await confirmPassword(oobCode, data.password);
        const uid = await signInUser(email, data.password);
        dispatch(setUid(uid));
        Analytics.trackEvent(categoryPassword, EVENT_ACTION.SUCCESS); //
        setShowSuccessScreen(true);
      } catch (error: any) {
        console.error('Invalid oobCode:', oobCode);
        setError('general');
        Analytics.trackEvent(categoryPassword, EVENT_ACTION.ERROR, { error: error?.message, code: error?.code });
      }
    }
  }

  const handleCloseErrorClick = async () => {
    setError(null);
    try {
      await reSendPasswordReset(email, platform);
      Analytics.trackEvent(`${categoryPassword}_re_sent_letter`, EVENT_ACTION.SUCCESS);
      setShowSuccessReSendPopup(true);
    } catch (error: any) {
      setError('general');
      Analytics.trackEvent(`${categoryPassword}_re_sent_letter`, EVENT_ACTION.ERROR);
    }
  }

  const handleSkipErrorClick = () => {
    setError(null);
  }

  const handleCloseEmailSuccessPopup = () => {
    setShowSuccessEmailRecoveredPopup(false);
    window.location.href = continueUrl;
  }

  const errorTKey = `${tKey}.${error === 'general' ? 'error_popup' : "error_popup_link_used"}`;

  if (showSuccessScreen) {
    return <SuccessScreen image={successImage} isCenteredContent={isCenteredSuccessContent} />;
  }

  return (
    <>
      {loading && <Preloader />}
      {mode !== 'recoverEmail' &&
        <div className={classes.reset_password_wrap}>
          <div className={classes.blur} />
          <div className={classes.top_part}>
            <Title level='h2' className={classes.title}>
              <TM k={title} defaultKey={`${tKey}.title_text`} />
            </Title>
            <Passwords
              validation={validation}
              onInputChange={handleChange}
              data={data}
              withInputLabels={withInputLabels}
              isReset
            />
          </div>
          <Button
            type='submit'
            disabled={!isValid}
            className={classes.button}
            titleClassName={classes.button_title}
            title={t(`${tKey}.form_button`)}
            onClick={handleBtnClick}
          />
        </div>
      }
      <ErrorPopUp
        visible={!!error}
        title={t(`${errorTKey}.title`)}
        subTitle={t(`${errorTKey}.subTitle`)}
        btnTitle={t(`${errorTKey}.btnTitle`)}
        skipTitle={error === 'general' ? t(`${errorTKey}.skipTitle`) : undefined}
        onClose={handleCloseErrorClick}
        onSkipClick={handleSkipErrorClick}
      />
      <SuccessPopup
        category={`${categoryPassword}_re_sent_letter`}
        title={t(`${tKey}.success_popup.title`)}
        button_text={t(`${tKey}.success_popup.btnText`)}
        text={<T k={(`${tKey}.success_popup.text`)} tOptions={{ email }} />}
        isOpen={showSuccessReSendPopup}
        className={classes.success_popup}
      />
      <SuccessPopup
        category={categoryEmail}
        title={t(`${tKey}.success_popup.congratulations`)}
        button_text={t(`${tKey}.success_popup.btnText`)}
        text={t(`${tKey}.success_popup.recovered`)}
        isOpen={showSuccessEmailRecoveredPopup}
        className={classes.success_popup}
        onSubmit={handleCloseEmailSuccessPopup}
      />
    </>
  )
}
export default ModuleReAuthentication;