import { type FC, type ReactNode, useEffect } from 'react';
import Lottie from 'lottie-react-web';
import classnames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { T, t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Button, LinkButton, Title, Image } from '@web-solutions/core/ui-elements';

import { checkIsAndroidTikTokBrowser } from '@web-solutions/core/utils/common';

import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { Text } from '@web-solutions/core/ui-elements';

import { ROUTES } from '../../../../constants';

import type { Theme } from '../../../..';

import welcome from './animations/welcome.json'

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'palm_reading.QUIZ_PALM_READING_WELCOME';
const eventCategory = 'quiz_palm_reading_welcome';

interface DefaultWelcomeBaseProps {
  subtitle?: string,
  badges?: ReactNode,
  isPalmDisclaimerVisible?: boolean,
  theme?: Theme;
  privacyContent?: ReactNode,
}

const DefaultWelcomeBase: FC<DefaultWelcomeBaseProps> = ({ subtitle, badges, isPalmDisclaimerVisible, theme, privacyContent }) => {
  const navigateTo = useNavigation();

  const isDarkTheme = theme?.isDarkTheme;
  const isLightTheme = theme?.isLightTheme;

  useEffect(() => {
    Analytics.trackEvent(eventCategory, EVENT_ACTION.OPEN);
  }, []);

  const isTikTok = checkIsAndroidTikTokBrowser();

  const handleBtnClick = () => {
    Analytics.trackEvent(eventCategory, EVENT_ACTION.SUCCESS);

    navigateTo(ROUTES.CAPTURE);
  };

  const handleUploadClick = () => {
    Analytics.trackEvent(eventCategory, EVENT_ACTION.SUCCESS);

    navigateTo(ROUTES.UPLOAD);
  };

  return (
    <div className={classnames(classes.welcome, {
      [classes.light]: isLightTheme,
      [classes.dark]: isDarkTheme
    })}>
      <>
        <div>
          <Title level='h2' className={classes.welcome__title}>{
            <T k={`${tKey}.title`} />}
          </Title>
          {subtitle && (
            <Text className={classes.subtitle}>
              {subtitle}
            </Text>
          )}
        </div>
        <div className={classes.imageWrapper}>
          {badges}
          {!isLightTheme && !isDarkTheme ?
            <Lottie
              options={{ animationData: welcome }}
              width={210}
              height={210}
            />
            :
            <Image
              img={IMAGES.QUIZ_PALM_READING_WELCOME}
              className={classes.welcome__image}
              alt="hand"
              height="210"
              width="210"
            />
          }
        </div>
      </>
      {isPalmDisclaimerVisible && (
        <Text className={classes.disclaimer}>
          {t(`${tKey}.disclaimer`)}
        </Text>
      )}
      {privacyContent
        ? privacyContent
        : <Text className={classes.privacy_disclaimer}>
          {t(`${tKey}.privacy_disclaimer`)}
        </Text>
      }
      {
        isTikTok ?
          <div className={classes.buttons_wrapper}>
            <Button
              title={t(`${tKey}.upload`)}
              onClick={handleUploadClick}
              className={classes.button}
              titleClassName={classes.btn_title}
            />
            <LinkButton className={classes.link} onClick={handleBtnClick}>
              {t(`${tKey}.button_title`)}
            </LinkButton>
          </div>
          :
          <div className={classes.buttons_wrapper}>
            <Button
              role='button'
              type='submit'
              title={t(`${tKey}.button_title`)}
              onClick={handleBtnClick}
              className={classes.button}
              titleClassName={classes.btn_title}
            />
            <LinkButton className={classes.link} onClick={handleUploadClick}>
              {t(`${tKey}.upload`)}
            </LinkButton>
          </div>
      }
    </div>
  );
}

export default DefaultWelcomeBase;