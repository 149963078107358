import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';

import Attribution, { LinkTarget } from '@web-solutions/module-attribution';

import { selectUid } from '../store/selectors';

export const useGetContinueUrl = () => {
  const uid = useSelector(selectUid);
  const { platform, email, mode } = queryString.parse(window.location.search);

  const [appLink] = useState(() => Attribution.getLinkToApp(platform ? platform === 'web' ? LinkTarget.WEB : LinkTarget.DEFAULT : undefined));

  const [url, setUrl] = useState(() => appLink);

  useEffect(() => {
    const parsedUrl = queryString.parseUrl(appLink);

    const newUrl = queryString.stringifyUrl({
      url: parsedUrl.url,
      query: {
        ...parsedUrl.query,
        email,
        source: mode,
        ...(uid && { [platform === 'web' ? 'idfm' : 'uuid']: uid }),
      },
    });

    setUrl(newUrl);
  }, [uid]);

  return { url, uid };
};
