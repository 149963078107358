import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';

import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { cacheImagesCascade } from '@web-solutions/core/utils/images';

import { DIMS, ImgData, LoadMethod, ROUTES } from '../../constants';

import { UploadPopup } from './popup';
import { IMAGES } from './images';

import classes from './style.module.scss';

cacheImagesCascade([IMAGES.BG]);

const eventCategory = 'quiz_palm_reading_upload';

interface PalmReadingUploadProps {
  onSuccess: (data: ImgData, type: LoadMethod) => void;
}

const PalmReadingUpload: React.FC<PalmReadingUploadProps> = ({
  onSuccess,
}) => {
  const location = useLocation();
  const navigateTo = useNavigation();
  // @ts-ignore
  const imgSrc = location?.state?.src;
  const isErrorPage = !!imgSrc;
  const tKey = `palm_reading.QUIZ_PALM_READING_UPLOAD.${isErrorPage ? 'error' : 'choose_image'}`;


  const handleBackClick = useCallback(() => {
    Analytics.trackEvent(eventCategory, 'click_back');
    navigateTo(`../${ROUTES.WELCOME}`, { relative: 'path', replace: true });
  }, [navigateTo]);

  return (
    <div className={classes.upload}>
      <div className={classes.back} onClick={handleBackClick} />
      {isErrorPage && (
        <img src={imgSrc} alt="palm" width={DIMS.width} />
      )}
      <UploadPopup
        onSuccess={onSuccess}
        tKey={tKey}
        isErrorPage={isErrorPage}
      />
    </div>
  );
};

export default PalmReadingUpload;