import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';

interface Lines {
  [key: string]: [number, number][],
}

export const createLines = (data: Lines) => {
  const lines = _mapValues(data, (v, k) => _map(v, item => { return { x: item[0], y: item[1] } }));
  Object.keys(lines).forEach(k => {
    if (!lines[k]?.length) {
      delete lines[k];
    }
  });
  return lines
}

export const createKeypoints = (data: { x: number, y: number }[]) => {
  const keypoints = data?.length === 21 ? [data[4], data[8], data[12], data[16], data[20]] : null;
  return keypoints
}

export const randomPrediction = () => {
  return Math.round(65 + (95 - 65) * Math.random());
}