import { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  getPalmReportData
} from '../../store/actions';

import {
  selectImg,
  selectLines,
  selectImgDims,
  selectKeyPoints,
  selectPalmReport
} from '../../store/selectors';

import { createKeypoints, createLines } from '../utils';

let fetching = false;

export const usePalmReport = (tryFetch?: boolean) => {
  const dispatch = useDispatch();

  const palmReport = useSelector(selectPalmReport);
  const localImg = useSelector(selectImg);

  useEffect(() => {
    if (tryFetch && !palmReport && !fetching) {
      dispatch(getPalmReportData());
      fetching = true;
    }
  }, [dispatch, palmReport, tryFetch])

  const palm = useMemo(() => {
    return palmReport?.left_hand_dots ?? palmReport?.right_hand_dots
  }, [palmReport?.left_hand_dots, palmReport?.right_hand_dots]);

  const img = useMemo(() => {
    if (localImg) {
      return localImg
    }

    return palmReport?.left_hand_path ?? palmReport?.right_hand_path;
  }, [palmReport?.left_hand_path, palmReport?.right_hand_path, localImg])

  const dims = useMemo(() => {
    return palm?.dims
  }, [palm?.dims]);

  const lines = useMemo((): ReturnType<typeof createLines> => {
    return palm?.lines ? palm?.lines : createLines(palm?.original_lines);
  }, [palm?.lines, palm?.original_lines])


  const keypoints = useMemo((): ReturnType<typeof createKeypoints> => {
    return palm?.keypoints ? palm?.keypoints : createKeypoints(palm?.hand_points)
  }, [palm?.hand_points, palm?.keypoints])

  const handResults = useMemo(() => {
    return palm?.overviewHandResults
  }, [palm?.overviewHandResults])

  return {
    img,
    dims: useSelector(selectImgDims) || dims,
    lines: (useSelector(selectLines) || lines) as typeof lines,
    keypoints: (useSelector(selectKeyPoints) || keypoints) as typeof keypoints,
    handResults,
    palm,
  }
}