import { ImagesMap } from '@web-solutions/core/interfaces/images';

import QUIZ_PALM_READING_WELCOME from './take-photo.webp';
import QUIZ_PALM_READING_WELCOME2X from './take-photo@2x.webp';
import QUIZ_PALM_READING_WELCOME3X from './take-photo@3x.webp';

import QUIZ_PALM_READING_WELCOME_LIGHT from './take-photo-light.webp';
import QUIZ_PALM_READING_WELCOME_LIGHT_2X from './take-photo-light@2x.webp';
import QUIZ_PALM_READING_WELCOME_LIGHT_3X from './take-photo-light@3x.webp';

import QUIZ_PALM_READING_WELCOME_DARK from './take-photo-dark.webp';
import QUIZ_PALM_READING_WELCOME_DARK_2X from './take-photo-dark@2x.webp';
import QUIZ_PALM_READING_WELCOME_DARK_3X from './take-photo-dark@3x.webp';

export const IMAGES: ImagesMap = {
  QUIZ_PALM_READING_WELCOME: {
    src: QUIZ_PALM_READING_WELCOME,
    srcSet: `${QUIZ_PALM_READING_WELCOME}, ${QUIZ_PALM_READING_WELCOME2X} 2x, ${QUIZ_PALM_READING_WELCOME3X} 3x`,
    themes: {
      light: {
        src: QUIZ_PALM_READING_WELCOME_LIGHT,
        srcSet: `${QUIZ_PALM_READING_WELCOME_LIGHT}, ${QUIZ_PALM_READING_WELCOME_LIGHT_2X} 2x, ${QUIZ_PALM_READING_WELCOME_LIGHT_3X} 3x`,
      },
      dark: {
        src: QUIZ_PALM_READING_WELCOME_DARK,
        srcSet: `${QUIZ_PALM_READING_WELCOME_DARK}, ${QUIZ_PALM_READING_WELCOME_DARK_2X} 2x, ${QUIZ_PALM_READING_WELCOME_DARK_3X} 3x`,
      }
    }
  }
};
