import { getOperatingSystemName } from '@web-solutions/core/utils/operating-system';

import { getTheme } from '@web-solutions/core/utils/images';

export const ROUTES = {
  WELCOME: 'welcome',
  CAPTURE: 'capture',
  ANALYZING: 'analyzing',
  UPLOAD: 'upload',
};


export const ERRORS = {
  NO_HAND: 'no_hand',
  TIMEOUT: 'timeout',
}

let w = window.innerWidth;
let h = window.innerHeight;
let pr;

if (getOperatingSystemName() === 'iOS' && h > w && w > 600) {
  pr = window.devicePixelRatio || 2;
  w = w / pr;
  h = h / pr;
}

export const DIMS = { width: w, height: h * 0.8, pr };

export const WIDTH_OFFSET = (DIMS.width - DIMS.height) / 2;

export enum LoadMethod {
  CAPTURE = 'capture',
  UPLOAD = 'upload',
}

export interface ImgData {
  img: string,
  width: number,
  height: number,
  ratio: number,
}


export const LINES = {
  LOVE: 'love',
  HEAD: 'head',
  LIFE: 'life',
  FATE: 'fate',
};

const LINES_COLORS_DEFAULT = {
  [LINES.LOVE]: '#F27067',
  [LINES.HEAD]: '#F9D05D',
  [LINES.LIFE]: '#19CEBB',
  [LINES.FATE]: '#C583FA'
};

const LINES_COLORS_DARK = {
  [LINES.LOVE]: '#FF406E',
  [LINES.HEAD]: '#FFE141',
  [LINES.LIFE]: '#50E7B4',
  [LINES.FATE]: '#B67BFF'
};

const handler = {
  get(target: Record<string, string>, prop: string) {
    if (getTheme() === 'dark') {
      return LINES_COLORS_DARK[prop]
    }
    return target[prop];
  },
};

export const LINES_COLORS = new Proxy(LINES_COLORS_DEFAULT, handler);

export const PALM_RATIO = window.location.pathname.split('/')[1] === 'chat-flow' ? 1 : 1.5;

