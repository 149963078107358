import React from 'react';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { ReactComponent as RescanIcon } from './icons/rescan.svg';

import classes from './style.module.scss';

interface PalmReadingAnalyzingProps {
  okText: string;
  onOkClick: () => void;
  cancelText: string;
  onCancelClick: () => void;
}

export const PalmReadingApproveFooter: React.FC<PalmReadingAnalyzingProps> = ({
  okText,
  onOkClick,
  cancelText,
  onCancelClick,
}) => {
  return (
    <div className={classes.footer}>
      <Button
        title={okText}
        onClick={onOkClick}
        iconRight={false}
      />
      {
        <LinkButton
          className={classes.back}
          onClick={onCancelClick}
        >
          <RescanIcon className={classes.icon} />
          {cancelText}
        </LinkButton>
      }
    </div>
  );
};
