import React, { useCallback } from 'react';

import Analytics from '@web-solutions/module-analytics';

import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { ROUTES, type ImgData } from '../../constants';

import PalmReadingCapture from './capture';

const eventCategory = 'quiz_palm_reading_capture';

interface Props {
  cameraInitTimeout: number;
  onSuccess: (img: ImgData) => void;
  onError: (error: unknown) => void;
}

const PalmReadingCaptureContainer: React.FC<Props> = ({ cameraInitTimeout, onSuccess, onError }) => {
  const navigateTo = useNavigation();

  const handleBackClick = useCallback(() => {
    Analytics.trackEvent(eventCategory, 'click_back');
    navigateTo(`../${ROUTES.WELCOME}`, { relative: 'path', replace: true });
  }, [navigateTo]);

  return (
    <PalmReadingCapture
      cameraInitTimeout={cameraInitTimeout}
      eventCategory={eventCategory}
      onBackClick={handleBackClick}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

export default PalmReadingCaptureContainer;
