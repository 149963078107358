import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdvisorCardData } from 'src/components/advisor-cards-swiper/types';
import { ReadingTypes, TopicsIds } from 'src/screens/quiz/nebula-views/constants';

export type ShippingAddress = {
  email: string,
  name: string,
  last_name: string,
  country: {
    value: string,
    label: string,
    state: boolean,
  },
  state: {
    value: string,
    label: string,
  },
  postal_code: string,
  city: string,
  address: string,
  phone: string,
}
type ShippingMethod = {
  id: string,
  deliverier: string,
  shipping_level: string,
  delivery_interval: string,
  price: string,
  currency: string
}

export interface ProfileState {
  authorized: boolean;
  pauseSubUsed: boolean | null;

  email: string;

  name: string;
  gender: string | null;
  age: string | null;
  relStatus: string | null;
  relyOn: string;
  personalInsight: string;

  astologerTimeBalance: number | null;
  birthDate: string | null; // YYYY-MM-DD
  birthTime: string | null; // HH:mm
  birthPlace: { label: string, lat: string, lon: string } | null;
  approximateLocation: {
    lat: string,
    lon: string,
    country_code: string,
    label: string,
  } | null;
  color: { color: string, text: string } | null;
  element: { image: any, text: string, id: string } | null;
  futureGoals: { id?: string, icon: string, text: string }[] | null;
  direction: string | null;
  goals: string[] | null;
  is_unsubscriber: boolean | null;
  lang: string;
  topics: TopicsIds[];
  typeOfReading: ReadingTypes[];
  soulmateInfo: {
    love: number;
    marriage: number;
    trust: number;
    teamwork: number;
    communication: number;
  };

  shippingAddress: ShippingAddress;
  shippingMethod: ShippingMethod;

  advisorsData: AdvisorCardData[];
  selectedAdvisorId: number | null;
  isChatUnlimited: boolean;
}

const initialState: ProfileState = {
  authorized: false,
  pauseSubUsed: null,

  email: '',

  soulmateInfo: {
    love: 88,
    marriage: 63,
    trust: 81,
    teamwork: 70,
    communication: 76,
  },

  name: '',
  gender: null,
  age: null,
  relStatus: null,
  relyOn: '',
  personalInsight: '',

  birthDate: null, // YYYY-MM-DD
  birthTime: null, // HH:mm
  birthPlace: null, // { label: string, lat: string, lon: string }

  approximateLocation: null,

  color: null, // { color: string, text: string }
  element: null, // { image: obj, text: string, id: string }
  futureGoals: null, // [{ icon: string, text: string}]

  topics: [],
  typeOfReading: [],

  direction: null,
  goals: null,
  astologerTimeBalance: null,
  lang: i18next.language,

  is_unsubscriber: null,

  shippingAddress: {
    email: '',
    name: '',
    last_name: '',
    country: {
      value: '',
      label: '',
      state: false,
    },
    state: {
      value: '',
      label: '',
    },
    postal_code: '',
    city: '',
    address: '',
    phone: '',
  },
  shippingMethod: {
    id: '',
    deliverier: '',
    shipping_level: '',
    delivery_interval: '',
    price: '',
    currency: ''
  },
  advisorsData: [],
  selectedAdvisorId: null,
  isChatUnlimited: false,
};


const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload || initialState.email;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setGender: (state, action: PayloadAction<string | null>) => {
      state.gender = action.payload
    },
    setAge: (state, action: PayloadAction<string>) => {
      state.age = action.payload
    },
    setRelStatus: (state, action: PayloadAction<string | null>) => {
      state.relStatus = action.payload
    },
    setRelyOn: (state, action: PayloadAction<string>) => {
      state.relyOn = action.payload
    },
    setPersonalInsight: (state, action: PayloadAction<string>) => {
      state.personalInsight = action.payload
    },
    setAstologerTimeBalance: (state, action: PayloadAction<any>) => {
      state.astologerTimeBalance = action.payload;
    },
    setBirthDate: (state, action: PayloadAction<any>) => {
      state.birthDate = action.payload;
    },
    setBirthTime: (state, action: PayloadAction<any>) => {
      state.birthTime = action.payload;
    },
    setBirthPlace: (state, action: PayloadAction<any>) => {
      state.birthPlace = action.payload;
    },
    setApproximateLocation: (state, action: PayloadAction<{
      lat: string,
      lon: string,
      country_code: string,
      label: string,
    }>) => {
      state.approximateLocation = action.payload;
    },
    setColor: (state, action: PayloadAction<any>) => {
      state.color = action.payload;
    },
    setElement: (state, action: PayloadAction<any>) => {
      state.element = action.payload;
    },
    setFutureGoals: (state, action: PayloadAction<any>) => {
      state.futureGoals = action.payload;
    },
    setGoals: (state, action: PayloadAction<any>) => {
      state.goals = action.payload;
    },
    setLang: (state, action: PayloadAction<any>) => {
      state.lang = action.payload;
    },
    setIsUnsubscriber: (state, action: PayloadAction<any>) => {
      state.is_unsubscriber = action.payload;
    },
    setAuthorized: (state) => {
      state.authorized = true;
    },
    setAstrologerTimeBalance: (state, action: PayloadAction<any>) => {
      state.astologerTimeBalance = action.payload;
    },
    setCriticalDirection: (state, action: PayloadAction<any>) => {
      state.direction = action.payload;
      state.goals = state.direction === action.payload ? state.goals : initialState.goals;
    },
    setDesirableGoals: (state, action: PayloadAction<any>) => {
      state.goals = action.payload;
    },
    setShippingAddress: (state, action: PayloadAction<ShippingAddress>) => {
      state.shippingAddress = action.payload;
    },
    setShippingMethod: (state, action: PayloadAction<ShippingMethod>) => {
      state.shippingMethod = action.payload;
    },
    generateSoulmateInfo: (state) => {
      const soulmateInfo = {
        love: randomPrediction(),
        marriage: randomPrediction(),
        trust: randomPrediction(),
        teamwork: randomPrediction(),
        communication: randomPrediction(),
      }
      state.soulmateInfo = soulmateInfo;
    },
    setAdvisorsData: (state, action: PayloadAction<AdvisorCardData[]>) => {
      state.advisorsData = action.payload;
    },
    setSelectedAdvisorId: (state, action: PayloadAction<number>) => {
      state.selectedAdvisorId = action.payload;
    },
    setChatUnlimited: (state, action: PayloadAction<any>) => {
      state.isChatUnlimited = action.payload
    },
    setTopics: (state, action: PayloadAction<TopicsIds[]>) => {
      state.topics = action.payload
    },
    setTypesOfReading: (state, action: PayloadAction<ReadingTypes[]>) => {
      state.typeOfReading = action.payload
    },
    setPauseSubUsed: (state, action: PayloadAction<boolean | null>) => {
      state.pauseSubUsed = action.payload
    },
  }
})

export const blacklist = ['authorized', 'selectedAdvisorId'];

export { slice as profileSlice };

export const randomPrediction = () => {
  return Math.round(65 + (95 - 65) * Math.random());
}
