import type { FC } from 'react'

import type { Theme } from '../..';

import { WelcomeDefault } from './welcome-default';

interface Props {
  isPalmDisclaimerVisible: boolean;
  theme?: Theme;
  component?: React.ReactNode,
}

export const Welcome: FC<Props> = ({ isPalmDisclaimerVisible, theme, component }) => {
  if (component) {
    return component;
  }

  return <WelcomeDefault isPalmDisclaimerVisible={isPalmDisclaimerVisible} theme={theme} />
}

export default Welcome;
