import { useCallback } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

export const useNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = useCallback((route: string, options?: NavigateOptions) => {

    navigate({
      pathname: route,
      search: window.location.search,
    }, options)
  }, [navigate]);

  return navigateTo;
}
