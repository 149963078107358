import classNames from 'classnames';

import { T, t } from '@web-solutions/module-localization';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { ImageSource } from '@web-solutions/core/interfaces/images';

import { Title, Text, Button } from '@web-solutions/core/ui-elements';

import { useGetContinueUrl } from '../../hooks/use-get-continue-url';

import classes from './style.module.scss';

const tKey = 'reset_password.success_screen'

interface Props {
  image: ImageSource;
  isCenteredContent?: boolean;
}

export const SuccessScreen: React.FC<Props> = ({ image, isCenteredContent }) => {
  const { url: continueUrl, uid } = useGetContinueUrl();

  const handleBtnClick = () => {
    Analytics.trackEvent('reset_password_back_to_app', EVENT_ACTION.CLICK, { idfm: uid });
    Analytics.flush();
    window.location.href = continueUrl;
  }

  return (
    <div className={classes.wrap}>
      <div className={classNames(classes.top_part, { [classes.centered_content]: isCenteredContent })}>
        <img src={image.src} srcSet={image.srcSet} alt="" />
        <Title level='h2'>{t(`${tKey}.title`)}</Title>
        <Text className={classes.sub_title}>
          <T k={`${tKey}.sub_title`} /></Text>
      </div>
      <Button
        title={t(`${tKey}.button_text`)}
        onClick={handleBtnClick}
      />
    </div>
  )
}