import React, { useRef } from 'react';

import { t } from '@web-solutions/module-localization';

import { Button, Image as ImageComponent, Title } from '@web-solutions/core/ui-elements';

import { cacheImagesCascade } from '@web-solutions/core/utils/images';

import { Text } from '@web-solutions/core/ui-elements';

import { DIMS, LoadMethod, ImgData, PALM_RATIO } from '../../../constants';

import { IMAGES } from './images';

import classes from './style.module.scss';

cacheImagesCascade(Object.values(IMAGES))

interface UploadPopupProps {
  tKey: string,
  isErrorPage?: boolean;
  onSuccess: (data: ImgData, type: LoadMethod) => void;
}

export const UploadPopup: React.FC<UploadPopupProps> = ({
  tKey,
  isErrorPage = false,
  onSuccess,
}) => {
  const refCanvas = useRef<HTMLCanvasElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const image = isErrorPage ? IMAGES.ERROR : IMAGES.BASE;

  const ratio = Math.max(Math.min(PALM_RATIO, window.devicePixelRatio || 1), 1);

  const handleBtnClick = () => {
    fileInput?.current?.click();
  };

  const handleFileInput = (e: Event): void => {
    // @ts-ignore
    const file = e?.target?.files[0];
    const img = new Image();

    img.src = URL.createObjectURL(file);

    img.onload = () => {
      if (!refCanvas.current) return
      const scale = Math.max(DIMS.width / img.width, DIMS.height / img.height);
      refCanvas.current.style.width = `${DIMS.width}px`;
      refCanvas.current.style.height = `${DIMS.height}px`;
      refCanvas.current.width = Math.floor(DIMS.width * ratio);
      refCanvas.current.height = Math.floor(DIMS.height * ratio);
      const dx = (DIMS.width / 2) - (img.width / 2) * scale;
      const dy = (DIMS.height / 2) - (img.height / 2) * scale;
      const ctx = refCanvas.current?.getContext('2d');
      ctx?.drawImage(img, dx, dy, img.width * scale * ratio, img.height * scale * ratio);
      const dataURL = refCanvas.current?.toDataURL('image/jpeg') || '';
      onSuccess({ img: dataURL, ...DIMS, ratio }, LoadMethod.UPLOAD);
    }
  }

  return (
    <div className={classes.popup}>
      <ImageComponent img={image} alt="palm" className={classes.image} />
      <div className={classes.content}>
        <Title level='h2' className={classes.title}>{t(`${tKey}.title`)}</Title>
        <Text className={classes.text}>{t(`${tKey}.text`)}</Text>
      </div>
      <input
        ref={fileInput}
        type="file"
        // @ts-ignore
        onChange={handleFileInput}
        className={classes.input}
        accept="image/*"
      />
      <Button
        title={t(`${tKey}.button_title`)}
        onClick={handleBtnClick}
        className={classes.button}
      />
      <canvas
        ref={refCanvas}
        width={DIMS.width}
        height={DIMS.height}
        className={classes.canvas}
      />
    </div>
  );
};
