import React from 'react';
import Modal from 'react-modal';

import { Title } from '@web-solutions/core/ui-elements';
import { Button } from '@web-solutions/core/ui-elements';
import { LinkButton } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface ErrorPopUpProps {
  isOpen: boolean,
  title: string,
  buttonTitle: string,
  skipButtonTitle?: string,
  children?: React.ReactNode,
  onClose: () => void,
  onSkipClick?: () => void,
}

const ErrorPopUp: React.FC<ErrorPopUpProps> = ({
  isOpen,
  title,
  buttonTitle,
  skipButtonTitle,
  children,
  onClose,
  onSkipClick,
}) => (
  <Modal
    isOpen={isOpen}
    className={classes.modal}
    overlayClassName={classes.overlay}
  >
    <Title level='h2' className={classes.title}>{title}</Title>
    {children}
    <Button
      flare={false}
      onClick={onClose}
      iconRight={false}
      className={classes.button}
      title={buttonTitle}
      titleClassName={classes.button__title}
    />
    {onSkipClick && (
      <LinkButton
        className={classes.skip}
        onClick={onSkipClick}
      >
        {skipButtonTitle}
      </LinkButton>
    )}
  </Modal>
);

export default ErrorPopUp;
