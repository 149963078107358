import React from 'react';

import { cacheImagesCascade } from '@web-solutions/core/utils/images';

import classes from './style.module.scss';

import { IMAGES } from './images';

cacheImagesCascade([IMAGES.SHAPE]);

const CameraShape: React.FC = () => {
  return (
    <div id="palm-shape" className={classes.shape}>
    </div>
  );
}

export default CameraShape;
