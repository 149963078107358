import _mapValues from 'lodash/mapValues';

import {
  QUIZ_NAMES,
  SUBSCRIPTION_NAMES,
  ADDITIONAL_SCREENS_NAMES,
  AfterPurchaseRoutes,
  type QuizNameKeys,
  type QuizNamesValues,
} from './screens';

export const SUBSCRIPTION = {
  [SUBSCRIPTION_NAMES.PRESUMMARY]: '/subscription/presummary',
  [SUBSCRIPTION_NAMES.MAIN]: '/subscription/main',
  [SUBSCRIPTION_NAMES.SUCCESS]: '/subscription/success',
  [SUBSCRIPTION_NAMES.COMPATIBILITY]: '/subscription/improve-reports-compatibility',
  [SUBSCRIPTION_NAMES.BIRTH_CHART]: '/subscription/improve-reports-birth-chart',
  [SUBSCRIPTION_NAMES.NUMEROLOGY_ANALYSIS]: '/subscription/improve-reports-numerology-analysis',
  [SUBSCRIPTION_NAMES.GUIDES]: '/subscription/guides',
  [SUBSCRIPTION_NAMES.ASTROLOGERS_SINGLE_PACK]: '/subscription/single-pack-offer',
  [SUBSCRIPTION_NAMES.ASTROLOGERS_PACKS]: '/subscription/astrologers-packs-offer',
  [SUBSCRIPTION_NAMES.COMPATIBILITY_BIRTH_CHART_ULTRA]: '/subscription/compatibility_birth_chart_ultra',
  [SUBSCRIPTION_NAMES.UPSALES_BASKET]: '/subscription/upsales-basket',
  [SUBSCRIPTION_NAMES.COMPATIBILITY_HARDCOVER]: '/subscription/compatibility-hardcover',
  [SUBSCRIPTION_NAMES.CREATE_ACCOUNT]: '/subscription/create_account',
  [ADDITIONAL_SCREENS_NAMES.MY_ACCOUNT]: '/my-account',
};

export const QUIZ: { [key in QuizNameKeys]: string } = _mapValues(QUIZ_NAMES, (v) => '/' + v.toLowerCase() + '/*');

export const ROUTES = {
  ...QUIZ,
  ...SUBSCRIPTION,
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  QUIZ_NAMES.QUIZ_ASTROCARTOGRAPHY_SLIDER,
  QUIZ_NAMES.QUIZ_MAGIC,
  QUIZ_NAMES.QUIZ_REPORT_LOADER,
  QUIZ_NAMES.QUIZ_ADVISORS_LOADER,
  QUIZ_NAMES.QUIZ_EMAIL,
  QUIZ_NAMES.QUIZ_EMAIL_WITH_ADVISOR,
  QUIZ_NAMES.WARMUP_LOADER,
  QUIZ_NAMES.QUIZ_TRIAL,
  QUIZ_NAMES.QUIZ_MAILING,
  QUIZ_NAMES.QUIZ_SURVEY,
  QUIZ_NAMES.CHAT_FLOW,
  QUIZ_NAMES.CHAT_FLOW_BRANCHING,
  QUIZ_NAMES.QUIZ_TRANSITION_LOADER_ANIMATION,
  QUIZ_NAMES.QUIZ_TRANSITION_REVIEWS,
  QUIZ_NAMES.QUIZ_ASTROLOGER_MINUTES,
  QUIZ_NAMES.QUIZ_ASTROLOGER_MINUTES_POPUP,
  QUIZ_NAMES.QUIZ_SELECT_PLANS_BY_TRIAL,
  QUIZ_NAMES.QUIZ_GIFT_BOX,
  QUIZ_NAMES.QUIZ_MAP_LOADER,
  QUIZ_NAMES.QUIZ_ASTROCARTOGRAPHY_MAP_LOADER
];

export const SCREENS_WITH_AUTH: QuizNamesValues = [
  QUIZ_NAMES.QUIZ_EMAIL,
  QUIZ_NAMES.QUIZ_EMAIL_WITH_ADVISOR,
];

export const AFTER_PURCHASE_ROUTES = [
  SUBSCRIPTION_NAMES.GUIDES,
  SUBSCRIPTION_NAMES.COMPATIBILITY,
  SUBSCRIPTION_NAMES.BIRTH_CHART,
  SUBSCRIPTION_NAMES.NUMEROLOGY_ANALYSIS,
  SUBSCRIPTION_NAMES.COMPATIBILITY_BIRTH_CHART_ULTRA,
  SUBSCRIPTION_NAMES.ASTROLOGERS_PACKS,
  SUBSCRIPTION_NAMES.ASTROLOGERS_SINGLE_PACK,
  SUBSCRIPTION_NAMES.UPSALES_BASKET,
  SUBSCRIPTION_NAMES.COMPATIBILITY_HARDCOVER,
  SUBSCRIPTION_NAMES.CREATE_ACCOUNT
] as AfterPurchaseRoutes;
