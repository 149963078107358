import { useEffect, type FC, type ReactNode } from 'react'
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { T, tm } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title, Text, Button, Image, Modal, type ModalProps } from '@web-solutions/core/ui-elements';

import { baseTkey, ANALYTICS } from './constants'

import { IMAGES } from './images'
import { QuestionIcon } from './icons'

import classes from './styles.module.scss'

interface AccountAlreadyExistsModalProps extends Omit<ModalProps, 'children'> {
  email: string,
  onSubmit: () => void
  alertImage?: ReactNode,
  title?: string,
  subtitle?: string,
  buttonTitle?: string,
  withAnalytics?: boolean,
}

export const AccountAlreadyExistsModal: FC<AccountAlreadyExistsModalProps> = ({ isOpen, withAnalytics = true, className, overlayClassName, email, alertImage, title, subtitle, buttonTitle, onSubmit }) => {
  useEffect(() => {
    if (isOpen && withAnalytics) {
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN)
    }
  }, [isOpen, withAnalytics])

  const handleSubmit = () => {
    withAnalytics && Analytics.trackEvent(ANALYTICS, EVENT_ACTION.CLICK, { email })

    onSubmit()
  }

  return (
    <Modal
      isOpen={isOpen}
      className={classNames(classes.modal, className)}
      overlayClassName={classNames(classes.overlay, overlayClassName)}
    >
      {alertImage ? alertImage : <Image img={IMAGES.ALERT} alt='alert' />}
      <Title level='h2' className={classes.title} >
        {tm(title, `${baseTkey}.title`, { defaultValue: '' })}
      </Title>
      <Text className={classes.subtitle}>
        {tm(subtitle, `${baseTkey}.subtitle`, { defaultValue: '' })}
      </Text>
      <Button title={tm(buttonTitle, `${baseTkey}.button_title`, { defaultValue: '' })} onClick={handleSubmit} />
      <div className={classes.contactUsWrapper}>
        <QuestionIcon />
        <Text className={classes.text}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <T k={`${baseTkey}.contact_us`} components={{ highlight: <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=${encodeURIComponent('Account already exists')}`} className={classes.highlighted} /> }} />
        </Text>
      </div>
    </Modal>
  )
}
